import React from "react";
import { Grid, Typography, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  cardContainer: {
    position: "relative",
    height: "100%",
    background: "#DCD0FF",
    marginTop: "50px",
    borderRadius: "10px",
    overflow: "hidden",
  },
  cardContent: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    maxWidth: "300px",
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    borderRadius: "10px",
    bottom: "0",
  },
  cardImage: {
    width: "100%",
    height: "auto",
    marginTop: "0px",
  },
  heading: {
    
    fontSize: "24px",
    fontWeight: "300",
    lineHeight: "26px",
    fontFamily: "Inria Serif",
    marginTop: "21px",
  },
  heading2: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24px",
    fontStyle: "italic",
    fontFamily: "Inter",
    marginTop: "15px",
  },
}));

const data = [
  {
    title: "Property Search",
    description:
      "Build a dynamic property search tool with customizable filters for location, price, type, size, amenities, and more.",
    img: "Images/hello1.png",
  },
  {
    title: "Financial Services",
    description:
      "Offer resources or partnerships with mortgage lenders or banks to help users secure financing for property purchases.",
    img: "Images/hello2.png",
  },
  {
    title: "Investment Opportunities",
    description:
      "Highlight investment properties, real estate development projects, or commercial opportunities for investors seeking to grow their portfolios.",
    img: "Images/hello3.png",
  },
  {
    title: "Market Insights",
    description:
      "Share market trends, statistics, and insights about local real estate markets to help buyers, sellers, and investors make informed decisions.",
    img: "Images/hello4.png",
  },
];

const FeatureCard = ({ title, description, img }) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardContainer}>
      <img src={img} alt={title} className={classes.cardImage} />
      <Box className={classes.cardContent}>
        <Typography className={classes.heading}>{title}</Typography>
        <Typography className={classes.heading2}>{description}</Typography>
      </Box>
    </Box>
  );
};

const FeaturesGrid = () => {
  return (
    <Grid container justifyContent="center" spacing={3}>
      {data.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <FeatureCard {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default FeaturesGrid;
