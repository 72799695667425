import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
// import { useEffect } from "react";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  makeStyles,
  Box,
  Typography,
  TextField,
  FormControl,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { apiconfig } from "../Config/ApiConfig";
import { height, padding } from "@mui/system";
import PhoneInput from "react-phone-input-2";

const initialValues = {
  name: "",
  number: "",
  email: "",
  city: "",
  profession: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  number: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  email: Yup.string().email("Invalid email address").required("Required"),
  city: Yup.string().required("Required"),
  profession: Yup.string(),
});

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflow: "hidden",
    color: '#000000',
    marginBottom: '50px',
    marginTop: '85px',
  },
  carousel: {
    backgroundImage: "url('Images/Rectangle 1.png')",
    height: "759px",
    width: "100%",
    // backgroundSize: "cover",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      height: "310px",
      width: "100%",
    },
  },
  slider: {
    position: "relative",
    overflow: "hidden",
    height: "100%",
    width: "100%",
   // backgroundSize: "cover",
    "& .slick-next": {
      display: "none !important",
    },
  },
  heading: {
    fontSize: "24px", // Reduced size
    fontWeight: "700",
    lineHeight: "36px", // Reduced size
    textAlign: "center",
    marginBottom: "20px", // Reduced size
    fontFamily: "inter",
    color: "white",
    borderRadius: "10px", // Reduced size
    background: "linear-gradient(to left, #FFD700, #000000)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px", // Reduced size
      lineHeight: "24px", // Reduced size
      marginBottom: "15px", // Reduced size
    },
  },
  inputLabel: {
    fontSize: "20px", // Reduced size
    fontWeight: "bold",
    lineHeight: "24px", // Reduced size
    fontFamily: "inter",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px", // Reduced size
      lineHeight: "20px", // Reduced size
      marginBottom: "8px", // Reduced size
    },
  },
  formBox: {
    position: "absolute",
    right: "10%",
    bottom: "50%",
    transform: "translate(50%, 50%)",
    background: "#FFFFFF",
    padding: "15px 20px",
    width: "80%",
    maxWidth: "400px",
    border: "2px solid black",
    backgroundColor: "aliceblue",
    borderRadius: "8px",
    [theme.breakpoints.down("lg")]: { // Large screens (desktops)
      right: "15%",
      width: "30%",
    },
    [theme.breakpoints.down("md")]: { // Medium screens (tablets)
      right: "23%",
      width: "85%",
    },
    [theme.breakpoints.down("sm")]: { // Small screens (mobiles)
      position: "relative",
      right: "-27%",
      transform: "none",
      marginTop: "70px",
      // padding: "15px 20px",
      textAlign: "center",
      // width: "90%",
    },
    [theme.breakpoints.down("xs")]: { // Extra small screens
      position: "relative",
      right: "-3%",
      transform: "none",
      marginTop: "50px",
      padding: "10px 15px",
      textAlign: "center",
      width: "95%",
    },
  },
  
  inputBox: {
    marginBottom: "8px", // Reduced size
    padding: "10px 10px", // Reduced size
    [theme.breakpoints.down("sm")]: {
      padding: "8px 8px", // Reduced size
    },
  },
  button: {
    color: "#ffffff",
    background: "#000000",
    borderRadius: "25px", // Reduced size
    height: "36px", // Reduced size
    fontFamily: "inter",
    fontSize: "24px", // Reduced size
    fontWeight: "800",
    lineHeight: "30px", // Reduced size
    width: "100%",
    maxWidth: "180px", // Reduced size
    "&:hover": {
      background: "#FFD700",
      boxShadow: "0px 4px 4px 0px #0a359040",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px", // Reduced size
      lineHeight: "20px", // Reduced size
      height: "28px", // Reduced size
      maxWidth: "150px", // Reduced size
    },
  },
  
}));


const slides = [
  { backgroundImage: "url('Images/background1.png')"  },
  { backgroundImage: "url('Images/background2.png')" },
  { backgroundImage: "url('Images/background3.png')" },
  { backgroundImage: "url('Images/background4.png')" },
];

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const classes = useStyle();
  const fetchData = async (values) => {
    try {
      const res = await axios.post(apiconfig.UserEnqeuri, {
        email: values.email,
        name: values.name,
        mobileNumber: JSON.stringify(values.number),
        location: values.city,
        // countryCode: JSON.stringify(values.countryCode),
        message: values.profession,
      });
      if (res.status === 200) {
        toast.success("Form submitted");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      }
    }
  };
  return (
    <div className={classes.root}>
      <ToastContainer position="top-right" />
      <Slider {...settings} className={classes.slider}>
        {slides.map((slide, index) => (
          <div key={index}>
            <Box
              className={classes.carousel}
              style={{ backgroundImage: slide.backgroundImage }}
            >
              {/* Slide content can go here */}
              
            </Box>
          </div>
        ))}
      </Slider>
      <Box className={classes.formBox}>
        <Typography className={classes.heading}>ENQUIRE NOW</Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            fetchData(values);
            // setSubmitting(false);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box className={classes.inputBox}>
                <Typography className={classes.inputLabel}>Your Name</Typography>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </FormControl>
              </Box>
              <Box className={classes.inputBox}>
                <Typography className={classes.inputLabel}>
                  Phone Number
                </Typography>
                <PhoneInput
                            country={"in"}
                            containerStyle={{ width: "100%"  }}
                            inputStyle={{
                              width: "100%",
                              height: "55px",
                              background: "transparent",
                              borderTop: "none",
                              borderLeft: "none",
                              borderRadius: "0px",
                              borderRight: "none",             
                            }}
                            variant="outlined"
                            type="number"
                            name="number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.countryCode}
                            error={touched.countryCode && Boolean(errors.countryCode)}
                            helperText={touched.countryCode && errors.countryCode}
                            />
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    name="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.number}
                    error={touched.number && Boolean(errors.number)}
                    helperText={touched.number && errors.number}
                  />
                </FormControl>
              </Box>
              <Box className={classes.inputBox}>
                <Typography className={classes.inputLabel}>Email id</Typography>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </FormControl>
              </Box>
              <Box className={classes.inputBox}>
                <Typography className={classes.inputLabel}>City</Typography>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </FormControl>
              </Box>
              <Box className={classes.inputBox}>
                <Typography className={classes.inputLabel}>
                  Profession
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="profession"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.profession}
                    error={touched.profession && Boolean(errors.profession)}
                    helperText={touched.profession && errors.profession}
                  />
                </FormControl>
              </Box>
              <button
                type="submit"
                disabled={isSubmitting}
                className={classes.button}
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default SliderComponent;
