import React from 'react'
import {Button, makeStyles} from "@material-ui/core"
const useStyle = makeStyles(() => ({
    button: {
        marginTop: "86px",
        // color: "#FFFFFF",
        fontFamily: "inter",
        fontSize: "22px",
        fontWeight: "800",
        lineHeight: "38px",
        borderRadius: "50px",
        background: "#edefe0",
        // webkitBoxShadow: "1px 10px 5px 0px yellow",
        // mozBoxShadow: "1px 10px 5px 0px yellow",
        // boxShadow: "1px 2px 1px 1px yellow",
        "& .hoverButton": {

         background:"red",// "linear-gradient(to right, #FFD7ff, #FFD7ff)",
        color: "red",

      },
    

      }
}))

const PrimaryButton = ( {children, style}) => {
    const clasess=useStyle()
  return (
    <Button className={clasess.button} style={style}>
      {children}
    </Button>
  )
}

export default  PrimaryButton
