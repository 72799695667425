import { Grid, Box, Typography, Button, makeStyles } from "@material-ui/core";
import React , { useEffect}from "react";
import Calculater from "./Calculater";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  mainbox: {    marginTop: '85px',  },
  heading1: {
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: "67px",
    lineHeight: "77px",
    marginLeft: "4px",
    marginTop: "42px",
       background: "linear-gradient(to left, #ffffff, #000000)",
          borderRadius: "15px",
          color:"white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "48px",
          background: "linear-gradient(to left, #ffffff, #000000)",
          borderRadius: "15px",
          color:"white",
      lineHeight: "58px",
      marginLeft: "20px",
      marginTop: "30px",
    },
  },
  heading2: {
    fontFamily: "Inter",
    fontWeight: "300",
    fontStyle: "italic",
    fontSize: "32px",
    lineHeight: "38px",
    marginLeft: "59px",
    marginTop: "23px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "28px",
      marginLeft: "20px",
      marginTop: "15px",
    },
  },
  button1: {
    color: '#9930EC',
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "36px",
    marginLeft: "59px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
      marginLeft: "20px",
      marginBottom: "20px",
    },
  },
  imageContainer: {
    width: "100%",
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  image: {
    width: "100%",
    height: "auto",
  },
}));

const Mortgage = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  return (
    <Grid container direction="column" alignItems="center" className={classes.mainbox}>
      <Box>
        <Typography className={classes.heading1}>Mortgage in Dubai</Typography>
        <Box style={{ maxWidth: "1292px" }}>
          <Typography className={classes.heading2}>
            Our qualified mortgage advisors can give you detailed and honest
            advice on mortgage products and the application process to make sure
            you’re looked after every step of the way.
          </Typography>
        </Box>
      </Box>

      <Grid container justifyContent="center" spacing={2} style={{ marginTop: '20px' }}>
        <Grid item>
          <Link to="/Properties" style={{ textDecoration: 'none' }}>
            <Button className={classes.button1}>FIND A PROPERTY</Button>
          </Link>
        </Grid>
        <Grid item>
          <Link to="/ContactUs" style={{ textDecoration: 'none' }}>
            <Button className={classes.button1}>CONTACT US</Button>
          </Link>
        </Grid>
      </Grid>

      <Box className={classes.imageContainer}>
        <img src="Images/mortgage.png" alt="Mortgage" className={classes.image} />
      </Box>

      <Calculater />
    </Grid>
  );
};

export default Mortgage;
