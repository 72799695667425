import React  ,{useEffect}from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import { Height } from '@material-ui/icons';
import { colors } from '@material-ui/core';
import { BsDisplay } from 'react-icons/bs';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{fontFamily:"inter", fontWeight:"700", fontSize:"64px", lineHeight:"77px", marginTop:"101px"}}>
        Privacy Policy
      </Typography>
      <Typography variant="body1" gutterBottom style={{fontFamily:"inter", fontWeight:"500", fontSize:"32px", lineHeight:"38px",}}>
      knightsfinestates is committed to protecting the privacy of our users. This Privacy Policy outlines the types of personal information we collect, how it is used, and the measures we take to safeguard your information.
      </Typography>

      <Box mt={4}>
        <Typography variant="h6"  style={{fontFamily:"inter", fontWeight:"700", fontSize:"48px", lineHeight:"58px", marginTop:"55px"}} >OUR CONTACT DETAILS</Typography>
        <Typography variant="body1" style={{fontFamily:"inter", fontWeight:"500", fontSize:"32px", lineHeight:"38px",}}>
          Address: 506, 5th floor, Pyramid Axis, Behind Croma, Baner, Pune, Maharashtra, India, 411045
        </Typography>
        <Typography variant="body1" style={{fontFamily:"inter", fontWeight:"500", fontSize:"32px", lineHeight:"38px",}}>Email: realestate.com</Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h6" style={{fontFamily:"inter", fontWeight:"700", fontSize:"38px", lineHeight:"58px",}}>THE TYPE OF PERSONAL INFORMATION WE COLLECT</Typography>
        <Typography variant="body1" style={{fontFamily:"inter", fontWeight:"500", fontSize:"32px", lineHeight:"38px",}}>
          We currently collect and process the following information:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" style={{fontFamily:"inter", fontWeight:"500", fontSize:"32px", lineHeight:"30px",}}>
              Personal identifiers, contacts and characteristics (for example, name and contact details)
            </Typography>
          </li>
        </ul>
      </Box>

      <Box mt={4}>
        <Typography variant="h6" style={{fontFamily:"inter", fontWeight:"500", fontSize:"36px", lineHeight:"40px",}}>HOW WE STORE YOUR PERSONAL INFORMATION</Typography>
        <Typography variant="body1" style={{fontFamily:"inter", fontWeight:"400", fontSize:"28px", lineHeight:"38px",}}>
          Your information is securely stored.
        </Typography>
        <Typography variant="body1" style={{fontFamily:"inter", fontWeight:"400", fontSize:"28px", lineHeight:"30px",}}>
          We retain your personal information for as long as is necessary to fulfill the purpose for which it was collected.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h6" style={{fontFamily:"inter", fontWeight:"700", fontSize:"48px", lineHeight:"58px",}}>HOW TO COMPLAIN</Typography>
        <Typography variant="body1" style={{fontFamily:"inter", fontWeight:"500", fontSize:"32px", lineHeight:"38px",}}>
          If you have any concerns about our use of your personal information, you can make a complaint to us at:
        </Typography>
        <Typography variant="body1" style={{fontFamily:"inter", fontWeight:"500", fontSize:"32px", lineHeight:"38px",}}>
          Address: 506, 5th floor, Pyramid Axis, Behind Croma, Baner, Pune, Maharashtra, India, 411045
        </Typography>
      </Box>

      <Box mt={4} style={{background:"#000000", height:"214px", color:"#fff",dispaly:"flex", alginItem:"center"}}>
        <Typography variant="body1" style={{fontFamily:"Inria Serif", fontWeight:"400", fontSize:"40px", lineHeight:"58px", width:"100%", padding:"50px"}}>
          Need Help? Call Our Support Team  24/7: 
          <Link href="tel:+919156764099" underline="none"> +91 9156764099</Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;