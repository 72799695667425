import React, { useEffect } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import LatestNews from "../latestNews";

const useStyles = makeStyles(() => ({
  mainBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "61px",
    boxSizing: "border-box",
    maxHeight: "829px",
    paddingBottom: "100px",
    position: "relative",
    marginTop: '85px',

    // maxWidth: "1434px",
  },
  heading: {
    color: "#000000",
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: "44px",
    lineHeight: "77px",
    background: "linear-gradient(to right, #000000, #ffffff)",
    borderRadius: "15px",
    color:"white"
  },
  heading1: {
    fontFamily: "Inter",
    fontWeight: "800",
    fontSize: "44px",
    lineHeight: "77px",
    marginTop: "41px",
    padding: "19px",
    background: "linear-gradient(to right, #000000, #ffffff)",
    borderRadius: "15px",
    color:"white"
  },
  heading2: {
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "32px",
    lineHeight: "38px",
    marginTop: "41px",
    fontStyle: "italic",
    padding: "19px"
  },
  imgBox: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: "15px",
  },
  imgBox2: {
    pagdding: "7px",
  },
}));

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();

  return (
    <Box>
      <Grid container className={classes.mainBox}>
        <Grid item xs={12} md={4}>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "20px",
            }}
          >
            <Typography className={classes.heading}>About Us</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={3}>
              <Box className={classes.imgBox}>
                <img
                  src="Images/Rectangle 4137.png"
                  width="100%"
                  height="199px"
                  alt="About Us 1"
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.imgBox2}>
                <Box className={classes.imgBox}>
                  <img
                    src="Images/Rectangle 4138.png"
                    width="100%"
                    height="276px"
                    alt="About Us 2"
                  />
                </Box>
                <Box className={classes.imgBox}>
                  <img
                    src="Images/Rectangle 4139.png"
                    width="100%"
                    height="276px"
                    alt="About Us 3"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.imgBox} style={{ alignItems: "start" }}>
                <img
                  src="Images/Rectangle 4140.png"
                  width="100%"
                  height="577px"
                  alt="About Us 4"
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.imgBox2}>
                <Box className={classes.imgBox}>
                  <img
                    src="Images/Rectangle 4141.png"
                    width="100%"
                    height="276px"
                    alt="About Us 5"
                  />
                </Box>
                <Box className={classes.imgBox}>
                  <img
                    src="Images/Rectangle 4142.png"
                    width="100%"
                    height="276px"
                    alt="About Us 6"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box className={classes.headingBox}>
        <Box>
          <Typography className={classes.heading1}>Our Agency Story</Typography>
        </Box>
        <Box>
          <Typography className={classes.heading2}>
            Key One Realty Group is a full-service premier real estate solution
            provider of high-quality services tailored to your speciﬁc needs.
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.heading2}>
            Our ability to develop and execute customized strategic solutions in
            support of our clients’ unique goals and assess further exempliﬁes
            our commitment to creating enduring, successful partnerships. Our
            success in attaining these goals, along with our well-regarded
            reputation has enabled us to forge relationships and partnerships
            with our clients around the world. Key One serves customers in
            buying, selling and leasing properties, property and facility
            management, holiday homes rentals, and interior design services
            providing a comprehensive suite of solutions through ﬁve uniﬁed
            divisions. Each division offers unique products and services but all
            operated under the Key One umbrella.
          </Typography>
        </Box>
      </Box>
      <LatestNews />
    </Box>
  );
};

export default AboutUs;
