import React ,{useEffect} from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Card, CardMedia, CardContent, Typography, Grid, Container } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    textAlign: 'center',
    color: '#000000',
    marginBottom: '50px',
    marginTop: '85px',
    "& .MuiTypography-root": {
      fontSize: '64px',
      fontWeight: '800',
      fontFamily: 'Inter',
      lineHeight: '77px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '28px',
      },
    },
  },
  image: {
    width: '100%',
    height: '100%',
    display: 'block',
  },
  overlay: {
    position: 'absolute',
    top: '20%', // Adjusted for desktop
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      top: '25%', // Adjusted for tablets
    },
    [theme.breakpoints.down('sm')]: {
      top: '30%', // Adjusted for mobile
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '3px',
    },
  },
  subtitle: {
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3px',
    },
  },
  mainbox: {
    "& .MuiContainer-root": {
      maxWidth: "1474px !important",
      width: "100%",
    },
  },
  card: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  cardMedia: {
    padding: '10px',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      width: '100%',
    },
  },
  scrollUpButton: {
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
     display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',

    },
  },
  scrollUpIcon: {
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

const listingsData = [
  {
    id: 1,
    address: 'Luma Park',
    city: 'Jumeirah Village Circle Dubai',
    price: 'AED 1.05 M',
    image: 'Images/first.png',
  },
  {
    id: 2,
    address: 'Dubai-based developer',
    city: 'Dubai',
    price: '10,400,000 AED',
    image: 'Images/Second.png',
  },
  {
    id: 3,
    address: 'Park Gate',
    city: 'Dubai Hills Estate',
    price: '10,400,000 AED',
    image: 'Images/third.png',
  },
  {
    id: 4,
    address: 'Majestic Vistas By Automobili Lamborghini',
    city: 'Dubai Hills Estate',
    price: '37,000,000 AED',
    image: 'Images/Four.png',
  },
  {
    id: 5,
    address: 'Mirage',
    city: 'The Oasis',
    price: '15,800,000 AED',
    image: 'Images/five.png',
  },
  {
    id: 6,
    address: 'Demi Sathu 49',
    city: 'Yan Nawa, Bangkok',
    price: '11,400,000 AED',
    image: 'Images/six.png',
  },
];

const Properties = () => {
  const classes = useStyles();

  const scrollUp = () => {
    window.scrollBy({
      top: 1000, // Adjust this value to scroll up by a different amount
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  return (
    <Box className={classes.mainbox}>
      <Box className={classes.root}>
        <img
          src="Images/background2.png"
          alt="Exclusive Property"
          className={classes.image}
        />
        <Box className={classes.overlay}>
          <Typography variant="h2" className={classes.title}>All PROPERTIES</Typography>
          <Typography variant="body1" className={classes.subtitle}>OUR EXCLUSIVE PROPERTIES</Typography>
        </Box>
        <div className={classes.scrollUpButton} onClick={scrollUp}>
          <Typography variant="h2" className={classes.scrollUpIcon}>↓</Typography>
        </div>
      </Box>
      <Container>
        <Grid container spacing={4}>
          {listingsData.map((listing) => (
            <Grid item xs={12} sm={6} md={4} key={listing.id}>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  className={classes.cardMedia}
                  image={listing.image}
                  alt={listing.address}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {listing.address}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {listing.city}
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {listing.price}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Properties;
