import React, { useState } from "react";
import CallIcon from '@material-ui/icons/Call';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Paper,
  makeStyles,
  withStyles,
  Slider,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: "inter",
    fontSize: "48px",
    fontWeight: "800",
    lineHeight: "58px",
    marginTop: "45px",
    background: "linear-gradient(to left, #ffffff, #000000)",
    borderRadius: "15px",
    color:"white",
  },
  heading2: {
    fontFamily: "inter",
    fontSize: "32px",
    fontWeight: "400",
    lineHeight: "38px",
    marginTop: "45px",
  },
  box1: {
    maxWidth: "801px",
    marginBottom: "81px",
    border: "1px solid",
    padding: "16px",
    marginTop:"45px",
    background:"#edefe0"
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const Calculater = () => {
  const [propertyPrice, setPropertyPrice] = useState(3000000);
  const [downPayment, setDownPayment] = useState(600000);
  const [interestRate, setInterestRate] = useState(4.49);
  const [duration, setDuration] = useState(10);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [currency, setCurrency] = useState('AED');

  const calculateMonthlyPayment = () => {
    const loanAmount = propertyPrice - downPayment;
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = duration * 12;
    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
    setMonthlyPayment(monthlyPayment.toFixed(2));
  };

  const handleDownPaymentChange = (event, newValue) => {
    setDownPayment(newValue);
  };

  const classes = useStyles();

  return (
    <Box p={4}>
      <Paper elevation={3} style={{ padding: "16px", height: "" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography className={classes.heading}>
              Mortgage Calculator
            </Typography>
            <Typography className={classes.heading2}>
              Thinking of buying a home? One of the first steps is to work out
              how much you could raise as a mortgage loan. We have created a
              simple mortgage calculator to indicate what a monthly mortgage
              repayment may be. Contact our mortgage experts to help secure a
              mortgage.
            </Typography>
            <Typography className={classes.heading}>
              Mortgage Services
            </Typography>
            <Typography className={classes.heading2}>
              You may be buying your first home in Dubai, or you may be planning
              on moving into a new property in a different area. Why not consult
              one of our dedicated mortgage advisors? Not only are they fully
              qualified and highly experienced in assisting residents and expats
              find suitable mortgage solutions, but they can also guide you
              through the full process so you don’t miss a step.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.box1}>
              <Typography variant="h6" gutterBottom style={{fontSize:"36px",fontFamily:"inter", lineHeight:"38px", fontWeight:"800", marginBottom:"20px", textAlign:"center", marginTop:"20px"}}>
                Calculate your Mortgage
              </Typography>
              <Box>
                <Typography>Property Price</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={propertyPrice}
                  onChange={(e) => setPropertyPrice(parseFloat(e.target.value))}
                  margin="normal"
                />
              </Box>
              <Typography gutterBottom>
                Down Payment ({currency}): {downPayment}
              </Typography>
              <PrettoSlider
                value={downPayment}
                onChange={handleDownPaymentChange}
                step={10000}
                min={0}
                max={propertyPrice}
                valueLabelDisplay="auto"
                margin="normal"
              />

              <Typography gutterBottom>Duration (Years)</Typography>
              <PrettoSlider
                value={duration}
                onChange={(e, newValue) => setDuration(newValue)}
                step={1}
                min={1}
                max={30}
                valueLabelDisplay="auto"
                margin="normal"
              />

              <Box>
                <Typography>Interest Rate (%)</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(parseFloat(e.target.value))}
                  margin="normal"
                />
              </Box>

              <FormControl fullWidth margin="normal">
                <InputLabel>Currency</InputLabel>
                <Select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <MenuItem value="AED">AED</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="INR">INR</MenuItem>
                  <MenuItem value="THB">THB</MenuItem>
                  {/* Add more currencies as needed */}
                </Select>
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                onClick={calculateMonthlyPayment}
                style={{ marginTop: "16px" }}
              >
                Calculate
              </Button>
              <Typography
                variant="h6"
                style={{ marginTop: "16px", fontSize: "32px" }}
              >
                Monthly Payment = {currency} {monthlyPayment}
                <hr />
              </Typography>
              <Typography  style={{fontSize:"32px",fontFamily:"inter", lineHeight:"38px", fontWeight:"700", marginBottom:"20px"}}>Speak with our Mortgage Experts Today</Typography>
              <Typography style={{fontSize:"32px",fontFamily:"inter", lineHeight:"38px", fontWeight:"700"}}>
                  <CallIcon  style={{ width:"64px",height:"56px",margin:"-5px" }}/>   2356662215
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Calculater;
