import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';

const images = [
  "Images/Expo_05.jpg",
  "Images/Expo_06.jpg",
  "Images/Expo_01.jpg",
  "Images/Expo_03.jpg",
];

const Banner = () => {
  const [visible, setVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showCount, setShowCount] = useState(0);

  useEffect(() => {
    const getRandomImageIndex = () => Math.floor(Math.random() * images.length);

    const showBanner = () => {
      if (showCount < 3) {
        setVisible(true);
        setCurrentImageIndex(getRandomImageIndex());
        const hideTimer = setTimeout(() => {
          setVisible(false);
          setShowCount(showCount + 1);
          const showTimer = setTimeout(showBanner, 3 * 60 * 1000); // 3 minutes
          return () => clearTimeout(showTimer);
        }, 9000); // Show for 9 seconds
        return () => clearTimeout(hideTimer);
      }
    };

    showBanner();
  }, [showCount]);

  const handleClose = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '9%',
        left: 10,
        width: '100%',
        height: '90vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '90%', // adjust width as needed for responsiveness
          height: '90%', // adjust height as needed for responsiveness
          maxHeight: '100vh', // adjust max height if needed
          maxWidth: '100vw', // adjust max width if needed
          overflow: 'hidden', // hide overflow if image exceeds box dimensions
          borderRadius: '15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={images[currentImageIndex]}
          alt="Banner"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain', // fit the entire image within the container
          }}
        />
        <button
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '1%', // adjust top position as needed (percentage)
            right: '3%', // adjust right position as needed (percentage)
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          <CloseIcon style={{ color: '#fff', fontSize: '3vw' }} /> {/* responsive font size */}
        </button>
      </Box>
    </Box>
  );
};

export default Banner;
