import { Button, Grid, Typography, Box ,makeStyles } from "@material-ui/core";
import React from "react";
const useStyle = makeStyles(() => ({
    mainbox:{
        color:'#FFFFFF',
        background:"#2B2929",
        height:"214px",
        display:"flex",
        justifyContent:'center',
        alignItems:"center",
        padding:'51px',
        marginTop:'47px'
    }, Button:{
        background:"#FFFFFF",
        fontSize:'24px',
        fontWeight:"800",
        fontFamily:'Inter',
        lineHeight:'29px',
        borderRadius:"5px",
        height:'81px',
        '&:hover': {
            background: "#FF0000", 
            boxShadow: "0px 4px 4px 0px #0a359040",
          },
    },
    heading:{
        fontSize:'40px',
        fontWeight:"400",
        fontFamily:'Inria Serif',
        lineHeight:'47px',
    }
}))
const Lokking = () => {
    const classes = useStyle();

  return (
    <Grid container spacing={2} className={classes.mainbox} >
      <Grid item md={6} sm={12}>
        <Box>
          <Typography className={classes.heading}>Looking to advertise a property? We can help.</Typography>
        </Box>
      </Grid>
      <Grid item md={6} sm={12}>
        <Box>
          <Button className={classes.Button}>List your properties with us</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Lokking;
