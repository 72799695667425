import { Box, makeStyles, Button, Divider, colors } from "@material-ui/core";
import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
// import Silder from "./Silder";
import { useNavigate } from "react-router-dom";
// import OurServices from "./OurServices";
// import Properties from "./Properties";
// import ContactUs from "./ContactUs";
// import clsx from "clsx";
// import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

import ClearIcon from '@material-ui/icons/Clear';
import { color, display, fontWeight, height, margin, padding, positions, width } from "@mui/system";
import Banner from "./Home/Bannr";
const drawerWidth = 340;
const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "start",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
    
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  subBox: {
    display: "none",
    zIndex: 1000, // Adjust z-index to a higher value
    [theme.breakpoints.down('sm')]: {
      position: "fixed",
      top: 0,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      background: "white",
      padding: "0px 0px 0px 21px",
    },
  },
  
  

    // background: "linear-gradient(to right, #FFD700, #000000)",
  
  menuBox: {
    display: "flex",
    position: "fixed", // Set position to fixed
    top: 0, // Stick it to the top of the viewport
    left: 0, // Align it to the left side of the viewport
    width: "100%", // Take up the full width
    gap: "5%",
    justifyContent: "center",
    background: "white",
    zIndex: 9999, // Set a high z-index to ensure it appears above other content
    // Add other styles as needed
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  
  menuIcon: {
    display: 'block', // Mobile par dikhega
    margin:"11px",
    [theme.breakpoints.up('md')]: {
      display: 'none', // Desktop par hide rahega
    },
  },
  navbotton: {
    fontSize: "131%",
    lineHeight: "36px",
    fontWeight: "700",
    fontFamily: "inter",
    // color: "#000000",
    // color: "white",
    [theme.breakpoints.only("md")]:{
      fontSize:"100%",
      // color: "#ffd700",
      
    },
    [theme.breakpoints.down("sm")]:{
      display:'none'
    }
  },
  sidenav:{
    color:"#000000",
    fontFamily:"Jomolhari",
    fontWeight:"bold",
    fontSize:"22px",
    padding: "2px",
    
    [theme.breakpoints.down("sm")]:{
      color:"#000000",
      fontFamily:"Jomolhari",
      fontWeight:"bold",
      fontSize:"22px",
      padding: "2px",
    
    }
  },
  
}));
const navItems = [
  { text: "Home", icon: <InboxIcon />, route: "/" },
  { text: "Services", icon: <MailIcon />, route: "/OurServices" },
  { text: "About Us", icon: <InboxIcon />, route: "/About" },
  { text: "Mortgage", icon: <MailIcon />, route: "/Mortgage" },
  { text: "Contact Us", icon: <InboxIcon />, route: "/ContactUs" },
  { text: "Properties", icon: <MailIcon />, route: "/Properties" },
];
const Header = () => {
  const classes = useStyle();
  const navigate = useNavigate();
  function AboutUs() {
    navigate("/About");
  }
  function Home() {
    navigate("/");
  }
  function OurServices() {
    navigate("/OurServices");
  }
  function Mortgage() {
    navigate("/Mortgage");
  }
  function Properties() {
    navigate("/Properties");
  }
  function ContactUs() {
    navigate("/ContactUs");
  }
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  console.log ("open", open)

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleNavigate = (path) => {
    navigate(path);
  };  

  return (
    <>
      <Box className={classes.mainBox}>
        <Box className={classes.subBox}>
        <Box>
        <MenuIcon onClick={handleDrawerOpen} className={classes.menuIcon}  />
        {/* <Banner/> */}
        </Box>

          <Box >
            <img src="Images/KNIGHTS-FIN_logo_02.png" alt="img" width="100px" height="80px" /> 
          </Box>
          {/* <Box >
            <img src="Images/flg.png" alt="img" />
          </Box> */}
        </Box>
        <Divider />
        <Box className={classes.menuBox}>
          <Box >
            <img src="Images/KNIGHTS-FIN_logo_02.png" alt="img" width="200px" height="80px" marginLeft="10px" /> 
          </Box>
          <Button className={classes.navbotton} onClick={Home}>
            {" "}
            Home
          </Button>
          <Button className={classes.navbotton} onClick={OurServices}>
            {" "}
            Services
          </Button>
          <Button className={classes.navbotton} onClick={AboutUs}>
            {" "}
            About Us
          </Button>
          <Button className={classes.navbotton} onClick={Mortgage}>
            {" "}
            Mortgage{" "}
          </Button>
          <Button className={classes.navbotton} onClick={ContactUs}>
            {" "}
            Contact Us
          </Button>
          <Button className={classes.navbotton} onClick={Properties}>
            {" "}
            Properties
          </Button>
          {/* <Banner/> */}
        </Box>
      </Box>
      <div className={classes.root}>
        <CssBaseline />

        <Drawer
          className={classes.drawer}
          onClose={()=> setOpen(false)}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
            <ClearIcon/>
            </IconButton>
            <Box>
            <img src="Images/Logo.png" alt="img" width="50px" height="50px" />
          </Box>

          </div>
          <Divider />
          <List>
            {navItems.map((item, index) => (
              <>
              {console.log(item)}
              <ListItem  button key={item.text} onClick={() => handleNavigate(item.route)}>
                <Typography  className={classes.sidenav}>{item.text}</Typography>
              </ListItem>
              </>
              
            ))}
          </List>
          <Divider />
          
        </Drawer>
      </div>
    </>
  );
};

export default Header;
