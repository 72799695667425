import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
    "& .MuiFab-primary": {
      background: "#7C3FA1"
    },
    "& .MuiSpeedDialAction-fab": {
      background: "7C3FA1"
    }
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

const actions = [
    { 
      icon: <PermIdentityIcon />, 
      name: 'Call', 
      action: () => window.open('tel:+918177083523', '_self') 
    },
    { 
      icon: <WhatsAppIcon />, 
      name: 'WhatsApp', 
      action: () => window.open('https://wa.me/918177083523', '_blank') 
    },
  ];
  
export default function SpeedDials() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.exampleWrapper}>
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                if (action.action) {
                  action.action();
                }
                handleClose();
              }}
            />
          ))}
        </SpeedDial>
      </div>
    </div>
  );
}
