import {
  Box,
  makeStyles,
  Button,
  Divider,
  Typography,
  Grid,
} from "@material-ui/core";
import React from "react";
const useStyle = makeStyles(() => ({
  heading1: {
    fontFamily: "Jomolhari",
    fontSize: "48px",
    fontWeight: "400",
    lineHeight: "76px",
    background: "linear-gradient(to left, #ffffff, #000000)",
          borderRadius: "15px",
          color:"white",
  },
  mainBox: {
    padding: "51px",
  },
  para1: {
    fontFamily: "Jomolhari",
    fontSize: "30px",
    fontWeight: "400",
    lineHeight: "48px",
    width: "100%",
    maxWidth: "1345px",
    marginBottom:'56px'
  },
  img1: {
    width: "100%",
    height: "340px",
    
  },
  heading3:{
    fontFamily:"Inria Serif",
    fontWeight:"700",
    fontSize:"32px",
    lineHeight:"38px",
    marginTop:'41px'
  }
}));

const Explore = () => {
  const clasess = useStyle();
  const locations = [
    { imgSrc: 'Images/Rectangle 12.png', name: 'Dubai' },
    { imgSrc: 'Images/Rectangle 13.png', name: 'Thailand' },
    { imgSrc: 'Images/Rectangle 14.png', name: 'Bangkok' },
  ];
  return (
    <Box className={clasess.mainBox}>
      <Typography variant="h5" className={clasess.heading1}>
        Explore the Dubai market
      </Typography>
      <Typography variant="body1" className={clasess.para1}>
        Dive deep into the UAE’s real estate market with prices, transaction
        histories and community insights to help you make an educated decision.
      </Typography>
      <Grid container spacing={3}>
      {locations.map((location, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Box>
            <img src={location.imgSrc} alt={location.name} className={clasess.img1} />
            <Typography className={clasess.heading3}>{location.name}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
    </Box>
  );
};

export default Explore;
