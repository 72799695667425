import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Grid, Box } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { fontWeight } from "@mui/system";
// import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  heading1: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "40px",
    marginBottom: "40px",
    padding: "14px",
    background: "linear-gradient(to left, #ffffff, #000000)",
          borderRadius: "15px",
          color:"white",
  },
  heading2: {
    fontFamily: "Jost",
    fontSize: "19px",
    fontWeight:"bold",
    lineHeight: "33px",
    marginBottom: "14px",
    padding: "14px"
  },
  button1: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "20px",
    fill: "#FFFFFF",
    color: "#FFFFFF",
    background: "#000000",
    borderRadius: "5px",
    padding: "13px 31px",
    margin: "0 auto",
    width: "100%"
  },
}));

function getSteps() {
  return ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"];
}

function getStepContent(step) {
    switch (step) {
      case 0:
        return {
          primary: "Submit your request via the Find An Contact page.",
          secondary: "Your request will be received directly by our team of seasoned investment consultants."
        };
      case 1:
        return {
          primary: "An introductory phone call is scheduled.",
          secondary: "We’ll arrange a convenient time to speak further about your investment goals.."
        };
      case 2:
        return {
          primary: "We find you the best options.",
          secondary: "We’ll arrange a convenient time to speak further about your investment goals."
        };
      case 3:
        return {
          primary: "We help to guide your purchasing decision.",
          secondary: "We’ll scour our extensive database of Dubai’s leading property investments to find you the best opportunities."
        };
      case 4:
        return {
          primary: "You have a new addition to your portfolio.",
          secondary: "We’ll assist you through every stage of the purchasing process, bridging the gap between you and the developer and ensuring the transaction goes as smoothly as possible. "
        };
      default:
        return {
          primary: "Unknown step",
          secondary: ""
        };
    }
  }
  

export default function ExploreMore() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const stepRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      const activeIndex = stepRefs.current.findIndex((ref) => {
        const { offsetTop, offsetHeight } = ref;
        return (
          scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight
        );
      });

      if (activeIndex !== -1 && activeIndex !== activeStep) {
        setActiveStep(activeIndex);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeStep]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Box style={{ width: "100%", maxWidth: "350px" }}>
              <Typography className={classes.heading1}>OUR PROCESS</Typography>
              <Typography className={classes.heading2}>
                Our service ensures you’ll find the perfect property.
              </Typography>
              <Typography className={classes.heading2}>
                We provide you with market analysis and strategic advice on
                where to buy, as well as assist with the negotiation and
                purchase process from start to finish.
              </Typography>
              <Button variant="contained" className={classes.button1}>
                Find An INVESTMENT
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label} active={true} >
                  <StepLabel >{label}</StepLabel>
                  <div ref={(el) => (stepRefs.current[index] = el)}>
                    <Typography >{getStepContent(index).primary}</Typography>
                    <Typography >{getStepContent(index).secondary}</Typography>
                  </div>
                </Step>
              ))}
            </Stepper>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
