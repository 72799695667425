import React from "react";
import { Grid, Box, makeStyles, Typography, colors } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: 'url("Images/back4.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    
  },
  imageBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom:'152px',
    marginTop:"104px",
    width:"100%"
  },
  image: {
    width: "100%",
    maxWidth: "300px",
    height: "auto",
  },
  heading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom:"20px"
    

  },
  heading2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading3:{
    fontFamily:'inter',
    fontSize:"64px",
    lineHeight:"77px",
    fontWeight:'700',
    color:"#FFFFFF",
    paddingTop:"50px"
  },
  heading4:{
    fontFamily:'inter',
    fontSize:"24px",
    lineHeight:"29px",
    fontWeight:'700',
    color:"#FFFFFF"
  }
}));

const Meet = () => {
  const classes = useStyles();

  return (
    <Box className={classes.background}>
      <Box className={classes.heading}>
        <Typography className={classes.heading3}>Meet Our Partners</Typography>
      </Box>
      <Box className={classes.heading2}>
        <Typography className={classes.heading4} >
          Our trusted partners in real estate. Top Real Estate Developers In
          Dubai{" "}
        </Typography>
      </Box>
      <Grid container >
        {["image 1.png", "image 2.png", "image 3.png", "image 4.png"].map(
          (imgSrc, index) => (
            <Grid item lg={3} xs={12} key={index}>
              <Box className={classes.imageBox}>
                <img
                  src={`Images/${imgSrc}`}
                  alt={`Image ${index + 1}`}
                  className={classes.image}
                />
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default Meet;
