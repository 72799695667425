import { Grid, Box, makeStyles, Typography, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import FeatureCard from "./FeatureCard";
import { borderRadius, color, padding } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  mainbox: {    marginTop: '85px',  },
  heading: {
    fontSize: "3rem", // Adjust font size for smaller screens
    fontWeight: "bold",
    lineHeight: "1.2",
    color: "white",
    background: "linear-gradient(to right, #000000, #ffffff)",
    borderRadius: "15px",
    fontFamily: "investors",
    paddingTop: "10px",
    // width: "51px",
    padding: theme.spacing(1), // Use Material-UI spacing for consistent padding
    textAlign: "center", // Center text on all screen sizes
  },
  heading2: {
    fontSize: "1.5rem", // Adjust font size for smaller screens
    fontWeight: "bold",
    lineHeight: "1.6",
    fontFamily: "Inter",
    paddingTop: "11%",
    padding: theme.spacing(2), // Use Material-UI spacing for consistent padding
    // textAlign: "center", // Center text on all screen sizes
  },
  imgbox: {
    marginTop: theme.spacing(8), // Use Material-UI spacing for consistent margin
    marginBottom: theme.spacing(5), // Use Material-UI spacing for consistent margin
  },
  para1: {
    fontSize: "2.625rem", // Adjust font size for smaller screens
    fontWeight: "700",
    lineHeight: "1.25",
    fontFamily: "Inter",
    fontStyle: "italic",
    maxWidth: "483px",
    textAlign: "center", // Center text on all screen sizes
    margin: "0 auto", // Center horizontally
    padding: theme.spacing(11), // Use Material-UI spacing for consistent padding
    [theme.breakpoints.down("sm")]: {
      // Adjust padding and font size for smaller screens
      fontSize: "2rem",
      padding: theme.spacing(4),
    },
  },
  
  img2: {
    maxWidth: "100%", // Ensure image doesn't exceed container width
    height: "auto", // Maintain aspect ratio
    display: "block",
    margin: "0 auto", // Center image horizontally
  },
}));

const OurServices = () => {
  const classes = useStyles();
  const theme = useTheme(); // Access theme object for responsive styling

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className={classes.mainbox} spacing={2}>
        <Grid item xs={12} lg={7}>
          <Box>
            <Typography className={classes.heading}>Our Services</Typography>
            <Typography className={classes.heading2}>
              Discover your dream property with our comprehensive real estate services. From finding the perfect home to navigating complex transactions, our expert team is dedicated to providing personalized guidance and unmatched expertise every step of the way. Whether you're buying, selling, or investing, trust us to help you achieve your real estate goals efficiently and effectively. Explore a seamless and stress-free experience tailored to your needs with our trusted real estate services.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} lg={5}>
          <Box className={classes.imgbox}>
            <video style={{ maxWidth: "100%", height: "auto", width: "100%" }} controls autoPlay>
              <source src="Video/HomeVideo.mp4" type="video/mp4" />
            </video>
          </Box>
        </Grid>
      </Grid>
      <FeatureCard />
      <Grid container style={{ background: theme.palette.primary.main, marginTop: "80px" }}>

        <Grid item xs={17} sm={6} md={4}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <img src="Images/our.png" alt="our 6" className={classes.img2} />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={8}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <Typography className={classes.para1}>
              Unlock the door to your dreams with our expertise in real estate.
            </Typography>
            {/* <Box style={{ position: "absolute", right: 10, bottom: 180 }}>
              <img src="Images/gift.png" className={classes.img2} />
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default OurServices;
