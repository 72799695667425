import React, { useEffect } from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import { Box, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import Meet from "./Meet";
import ContactForm from "./form";
import { display } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  
  background: {
    backgroundImage: 'url("Images/back6.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    marginBottom: "82px",
    marginTop: "42px",
    position: "relative",
    height: "805px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      paddingBottom: "40px",
      paddingTop: "40px",
    },
  },
  box1: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
       padding: theme.spacing(9),
      // paddingBottom: "120px",
    },
  },
  heading: {
    fontSize: "44px",
    fontWeight: "700",
    fontFamily: "inter",
    lineHeight: "77px",
    [theme.breakpoints.down("sm")]: {
      // display:"none",
      fontSize: "1px",
      lineHeight: "40px",
    },
  },
  heading2: {
    fontSize: "26px",
    fontWeight: "700",
    fontFamily: "inter",
    lineHeight: "43px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "25px",
      // paddingTop: "120px",
    },
  },
  box2: {
    background: "#fff",
    width: "35%",
    border: "1px solid #000000",
    borderRadius: "10px",
    marginBottom: "20px",
    
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginBottom: "22%",
      marginLeft: "26%",
      // paddingTop: "10px",
    },
  },
  box3: {
    textAlign: "center",
    padding: theme.spacing(2),
    flexDirection: "column", // For mobile-first approach
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: theme.spacing(1),
      justifyContent: "center",
      padding: "10px",
      alignItems: "center",
    },
  },
  
  
  headingBox: {
    fontSize: "30px",
    fontWeight: "600",
    fontFamily: "inter",
    lineHeight: "48px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      marginLeft: "10px",
      lineHeight: "0px",
    },
  },
  headingBox2: {
    fontSize: "22px",
    fontWeight: "600",
    fontFamily: "inter",
    lineHeight: "38px",
    
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
      marginTop: "2px",
      marginLeft: "2px",
      lineHeight: "12px",
    },
  },
  icons: {
    width: "67px",
    height: "73px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "31px",
      height: "40px",
      marginBottom: "10px",
    },
  },
  mainbox: {
    width: "100%",
    marginBottom: "293px",
     marginTop: '85px',  
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },
  scrollUpButton: {
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
     display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',

    },
  },
  scrollUpIcon: {
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollUp = () => {
    window.scrollBy({
      top: 1000, // Adjust this value to scroll up by a different amount
      behavior: 'smooth',
    });
  };
  return (
    <>
      <Box className={classes.mainbox}>
        <Box className={classes.background}>
          <Box className={classes.box1}>
            <Typography style={{ paddingTop: "62px" }} className={classes.heading}>Contact Us</Typography>
          </Box>
          <Box className={classes.box1}>
            <Typography className={classes.heading}>Get Help & Friendly Support</Typography>
          </Box>
          <Box className={classes.box1}>
            {/* <Typography className={classes.heading2}>
              Looking for help or any support? We're available 24 hours a day.
            </Typography> */}
            <div className={classes.scrollUpButton} onClick={scrollUp}>
          <Typography variant="h2" className={classes.scrollUpIcon}>↓</Typography>
        </div>
          </Box>
          <Box style={{ position: "absolute", bottom: isMobile ? '0' : '-220px', width: "100%" }}>
            <Box style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row', justifyContent: "center", gap: "26px" }}>
              <Box className={classes.box2}>
                <Box className={classes.box3}>
                  <LocationOnIcon className={classes.icons} />
                </Box>
                <Box className={classes.box3}>
                  <Typography className={classes.headingBox}>Our Office</Typography>
                </Box>
                <Box className={classes.box3}>
                  <Typography className={classes.headingBox2}>
                  506,5th floor, Pyramid Axis, Behind Croma,
                    <br />
                    Baner, Pune, Maharashtra, India, 411045
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.box2}>
                <Box className={classes.box3}>
                  <PersonIcon className={classes.icons} />
                </Box>
                <Box className={classes.box3}>
                  <Typography className={classes.headingBox}>Customer Service</Typography>
                </Box>
                <Box className={classes.box3}>
                  <Typography className={classes.headingBox2}>
                  team@knightsfinestates.com
                    <br />
                    +918177083523
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <ContactForm />
      <Meet />
    </>
  );
};

export default ContactUs;
