import React from "react";
import { Formik, Form } from "formik";
import CallIcon from "@material-ui/icons/Call";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import { useNavigate } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import * as Yup from "yup";
import axios from "axios";
import { apiconfig } from "../Config/ApiConfig";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { ToastContainer } from "react-toastify";
import PinterestIcon from "@material-ui/icons/Pinterest";
import TwitterIcon from "@material-ui/icons/Twitter";
import {
  Typography,
  Box,
  Button,
  makeStyles,
  Grid,
  TextField,
  FormControl,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { color, margin, padding } from "@mui/system";
import { useState } from "react";


const useStyles = makeStyles((theme) => ({
  heading1: {
    color: "#FFFFFF",
    fontFamily: "Felix Titling",
    fontWeight: "400",
    fontSize: "26px",
    lineHeight: "13px",
    marginTop: "88px",
    marginRight: "200px",
    maxWidth: "725px",
    paddingRight: "0px",
    textAlign: "center", // Always center the text
    [theme.breakpoints.down("sm")]: {
      marginRight: "5px",
      fontSize: "32px",
      lineHeight: "30px",
      marginTop: "20px", // Adjusted margin for smaller screens
    },
  },
  heading2: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "39px",
    marginTop: "89px",
    maxWidth: "406px",
    textAlign: "center", // Always center the text
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "25px",
      marginTop: "20px", // Adjusted margin for smaller screens
    },
  },
  
  button: {
    background: "#FF2424",
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "29px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "22px",
    },
  },
  input1: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "26px",
    fontStyle: "italic",
    marginTop: "42px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
      marginTop: "20px",
    },
  },
  form1: {
    color: "#FFFFFF",
    padding: "20px",
    "& .MuiFormLabel-root": {
      color: "#ffffff",
    },
  },
  mainbox: {
    background: "#2B2929",
    color: "#FFFFFF",
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    "& .react-tel-input, & .flag-dropdown": {
      background: "transparent",
      border: "none",
    },
    "& .react-tel-input, & .country-list": {
      background: "transparent",
    },
  },
  text2: {
    "& .MuiFormControl-root": {
      borderBottom: "2px solid #FFFFFF",
      height: "60px",
      [theme.breakpoints.down("sm")]: {
        height: "50px",
      },
    },
  },
  text1: {
    "& .MuiInput-root ": {
      height: "60px",
      color: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        height: "50px",
      },
    },
  },
  mainbox2: {
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  subBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiTypography-body1": {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  img1: {
    width: "100%",
    height: "100%",
    maxHeight: "188px",
    maxWidth: "208px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "100px",
      maxWidth: "100px",
    },
  },
  Icons: {
    height: "62px",
    width: "75px",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      width: "50px",
    },
  },
  heading3: {
    fontSize: "29px",
    fontFamily: "inter",
    fontWeight: "800",
    lineHeight: "43px",
    padding: "3%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  heading4: {
    fontSize: "19px",
    fontFamily: "inter",
    fontWeight: "400",
    lineHeight: "29px",
    color: "#000000B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  lastBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "54px",
    marginTop: "102px",
    color: "#000000B5",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "50px",
      gap: "20px",
    },
  },
  navbotton: {
    fontSize: "32px",
    fontFamily: "inter",
    fontWeight: "800",
    lineHeight: "38px",
    textDecoration: "underline",
    color: "#000000B5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  navbotton1: {
    fontSize: '20px',
    fontFamily: 'inter',
    fontWeight: '800',
    lineHeight: '24px',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
      order: 2,
      marginTop: '10px', // Adjust spacing
    },
  },
  navbutton5: {
    // position: 'fixed',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  iconsbox: {
    cursor: 'pointer',
    height: '42px',
    width: '52px',

  },
}));

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function AboutUs() {
    navigate("/About");
  }

  function OurServices() {
    navigate("/OurServices");
  }

  function ContactUs() {
    navigate("/ContactUs");
  }

  function PrivacyPolicy() {
    navigate("/PrivacyPolicy");
  }

const initialValues = {
    firstName: "",
    phoneInput: "",
    email: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    // phoneInput: Yup.number().
    // required("Phone Number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  

  const fechData = async (values) => {
    try {
      const res = await axios({
        method: "POST",
        url: apiconfig.UserEnqeuri,
        data: {
          email: values.email,
          name: values.firstName,
          mobileNumber: phoneNumber, 
          countryCode: countryCode, 
          message: values.message,
        },
      });
      if (res.status === 200) {
        console.log(res);
        toast.success("Form submitted successfully!");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.responseMessage || "An error occurred");
      } else {
        toast.error("An error occurred");
      }
    }
  };


  const handleClick = (url) => {
    window.open(url, "_blank");
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const handlePhoneChange = (phone, country) => {
    setPhoneNumber(phone);
    setCountryCode(country.countryCode);
  };
  
  return (
    <>
      <ToastContainer />
      <Grid container className={classes.mainbox}>
        <Grid item xs={12} sm={5}>
          <Box style={{ width: "100%" }}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: isMobile ? "center" : "end",
              }}
            >
              <Typography className={classes.heading1}>
                Find Your Next Home
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography className={classes.heading2}>
                Get in touch now before all our units get sold out! Our property
                specialists are more than happy to assist you.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.form1}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              console.log(values);
              // setSubmitting(true);
              fechData(values);
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <Box style={{ marginTop: isMobile ? "20px" : "90px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className={classes.input1}>
                          First Name
                        </Typography>
                        <FormControl fullWidth >
                          <TextField
                
                            className={classes.text1}
                            fullWidth
                            id="firstName"
                            name="firstName"
                            value={values.firstName}
                            autoComplete="fname"
                            onChange={handleChange}
                            
                            onBlur={handleBlur}
                            error={touched.firstName && Boolean(errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className={classes.input1}>
                          Mobile Number
                        </Typography>
                        <Box style={{ width: "100%" }}>

                          <PhoneInput
                            country={"in"}
                            containerStyle={{ width: "100%"  }}
                            inputStyle={{
                              width: "100%",
                              height: "55px",
                              background: "transparent",
                              borderTop: "none",
                              borderLeft: "none",
                              borderRadius: "0px",
                              borderRight: "none",
                              color: "#fff",
                            }}
                            buttonStyle={{ width: "50px" }}
                            className={classes.phoneInput}
                            type="phoneInput"
                            name="phoneInput"
                            onChange={handlePhoneChange} // Pass the handlePhoneChange function
                            onBlur={handleBlur}
                            value={phoneNumber}
                            error={touched.phoneInput && Boolean(errors.phoneInput)}
                            helperText={touched.phoneInput && errors.phoneInput}
                          />

                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography className={classes.input1}>
                          Email <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            className={classes.text1}
                            fullWidth
                            id="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography className={classes.input1}>
                          Message
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            className={classes.text1}
                            fullWidth
                            name="message"
                            id="message"
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.message && Boolean(errors.message)}
                            helperText={touched.message && errors.message}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "center" : "end",
                    }}
                  >
                    <Button type="submit" className={classes.button}>
                      Submit
                    </Button>
                    {console.log(errors, "hello")}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Box className={classes.mainbox2}>
        <Grid container spacing={1}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src="Images/Logo.png" className={classes.img1} alt="Logo" />
            </Box>
          </Grid>
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <Box className={classes.subBox}>
              <CallIcon className={classes.Icons} />
              <Typography className={classes.heading3}>Call Us</Typography>
              <Typography className={classes.heading4}>
                Free Calls <br />
                +918177083523
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box className={classes.subBox}>
              <LocationOnIcon className={classes.Icons} />
              <Typography className={classes.heading3}>Find Us</Typography>
              <Typography className={classes.heading4}>
                506,5th floor, Pyramid Axis, Behind Croma,
                <br />
                Baner, Pune, Maharashtra, India, 411045
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <Box className={classes.subBox}>
              <EmailIcon className={classes.Icons} />
              <Typography className={classes.heading3}>Email Us</Typography>
              <Typography className={classes.heading4}>
                Direct Email
                <br />
                team@knightsfinestates.com
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.lastBox}>
          <Button className={classes.navbotton} onClick={AboutUs}>
            About Us
          </Button>
          <Button className={classes.navbotton} onClick={OurServices}>
            Services
          </Button>
          <Button className={classes.navbotton} onClick={ContactUs}>
            Contact Us
          </Button>
        </Box>
        <hr />
        <Box
          style={{
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box>

            <Button className={classes.navbotton1} onClick={PrivacyPolicy}>
              Privacy Policy
            </Button>
            <Button className={classes.navbotton7}>
              © 2024 knightsfinestates. All Rights Reserved.
            </Button>

          </Box>

          <Box display="flex" justifyContent="space-between" width="200px" >
            <InstagramIcon
              className={classes.iconsbox}
              onClick={() =>
                handleClick("https://www.instagram.com/knightsfinestates/")
              }
            />
            <PinterestIcon
              className={classes.iconsbox}
              onClick={() =>
                handleClick("https://in.pinterest.com/knightsfinestates/")
              }
            />
            <TwitterIcon
              className={classes.iconsbox}
              onClick={() => handleClick("https://x.com/EstatesFin16778")}
            />
            <FacebookIcon
              className={classes.iconsbox}
              onClick={() =>
                handleClick(
                  "https://www.facebook.com/profile.php?id=61559868392921"
                )
              }
            />
            <LinkedInIcon
              className={classes.iconsbox}
              onClick={() =>
                handleClick("https://www.linkedin.com/company/knightsfinestates/")
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;