import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { makeStyles, Box, Typography, Grid } from "@material-ui/core";
import axios from "axios";
import { apiconfig } from "../Config/ApiConfig";

const useStyles = makeStyles(() => ({
  carousel: {
    margin: "0auto",
    padding: "20px",

  },
  slideContainer: {
    padding: "20px",
    backgroundColor: "#f4f4f4",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    height:"100%",
    border:"1px solid #000000",
   
  },
  image: {
    width: "100%",
    borderRadius: "10px 10px 0 0",
    height:"340px"
  },
  content: {
    padding: "10px",
  },
  date: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#ff0000",
    marginBottom: "10px",
  },
  day: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#ff0000",
    marginLeft: "5px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  description: {
    fontSize: "14px",
    color: "#555",
  },
  mainbox:{
    display:'flex',
paddingRight:"20px"
    
  }
}));


const LatestNews = () => {
    const [data,setData] = useState([])
  const classes = useStyles();
const fechData= async()=>{
try {
    const res=await axios({
        method:"GET",
        url:apiconfig.newsList

    })
    if (res.status===200){
        console.log (res)
        setData(res.data.result)
    }
} catch (error) {
    console.log(error)
}
}
useEffect(()=>{
    fechData()
}, [])
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  

  return (
    <div className={classes.carousel}>
      <Typography
        style={{
          textAlign: "center",
          fontSize: "34px",
          lineHeight: "77px",
          fontWeight: "800",
          fontFamily: "Inter",
          margin: "19px",
          background: "linear-gradient(to left, #ffffff, #000000)",
          borderRadius: "15px",
          color:"white"
        }}
      >
        Latest News & Articles
      </Typography>
      <Slider {...settings} >
        {data.map((slide, index) => (
          <Box className={classes.mainbox}>
          <Box key={index} className={classes.slideContainer}>
            <img src={slide.urlToImage} alt={slide.title} className={classes.image} />
            <Box className={classes.content}>
              {/* <Typography className={classes.date}>
                {slide.publishedAt}
                <span className={classes.day}>{slide.day}</span>
              </Typography> */}
              <Typography className={classes.title}>{slide.title}</Typography>
              <Typography className={classes.description}>
                {slide.description}
              </Typography>
            </Box>
          </Box>
          </Box>
        ))}
      </Slider>
    </div>
  );
};

export default LatestNews;