import React from "react";
import { Typography, Box,  Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import  PrimaryButton from './Button'
import { borderRadius, padding } from "@mui/system";
import { Link } from "react-router-dom";

// import { color } from "@mui/system";
const useStyles = makeStyles((theme) => ({
  paragraph: {
    // fontFamily: "KoHo, sans-serif",
    fontWeight:"bold",
    fontSize: "21px",
    lineHeight: "31px",
    // paddingLeft:"47px",
    padding:"27px",
    [theme.breakpoints.down("sm")]:{
      // backgroundColor: '#4caf50',
      // color: 'white',
      paddingLeft:"20px",
      paddingRight:"1%",
      paddingTop:"11%",
      paddingBottom:"20px",
      color:"linear-gradient(to right, #FFD700, #000000)",
    }
    // background: "linear-gradient(to right, #FFD700, #000000)",
  
  },
  container: {
    maxWidth: "990px",
    margin: "0 auto",
    padding: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgBox:{
    width:"100%",
    height:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    padding: "50px",
    // borderRadius: "12px",

    // [theme.breakpoints.down("sm")]:{
    //   display:"none",
    // }
  },
 
  why1: {
    paddingRight: "75px",
    paddingLeft: "15px",
    fontFamily: "Jomolhari",
    fontSize: "32px",
    fontWeight: "400",
    lineHeight: "76px",
    textAlign:"center",
    color:"white",
    background: "linear-gradient(to left, #ffffff, #000000)",
    borderRadius: "11px",
  
    },
  why2: {
    fontSize: "31px",
    lineHeight: "50px",
    fontWeight: "300",
    fontFamily: "Jomolhari",
    paddingLeft:"47px",
  },
  box1: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    padding: "20px",
    // textAlign: "center", /* Ensures text content within the box is centered */
    width: "100%", /* Ensures the box takes the full width available */
    height: "100%", /* Ensures the box takes the full height available */
    boxSizing: "border-box" /* Ensures padding is included in the box dimensions */
  }
}));

const Why = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box sclassName={classes.container}>
        <Typography className={classes.paragraph}>
        The 5-star resorts in Dubai like The Address Boulevard are known to offer a lasting sense of tranquility, Atlantis The Palm offers breathtaking views of the Arabian Gulf, and The Ritz-Carlton, Dubai lets you redefine relaxation as well as rejuvenation.
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} md={4} className={classes.content}>
          <Box className={classes.box1}>
            <Typography variant="h5" className={classes.why1}>
              Why choose Properties?
            </Typography>
           <Box style={{height:"100%", width:"100%"}}>
           <Typography  className={classes.why2}>
              Properties offer potential for investment growth, stable income
              through rentals, and tax advantages, making them a popular choice
              for wealth building and financial security.
            </Typography>
           </Box>
           <Link to="/Properties" style={{ textDecoration: 'none' }}> 
            < PrimaryButton variant="contained" className={classes.button}>
              See Properties
            </ PrimaryButton>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box className={classes.imgBox}>
            <img
              src="Images/Rectangle 11.png"
              width="100%"
              height="493px"
              alt="Properties"
              className={classes.image}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Why;
