import "./App.css";
import React from "react";
import Homelayout from "./Layout/Homelayout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Error from "./Components/Error";
import Home from "./Components/Home/Home";
import AboutUs from "./Components/Static/AboutUs";
import OurServices from "./Components/OurServices";
import Mortgage from "./Components/Mortgage";
import Properties from "./Components/Properties";
import ContactUs from "./Components/ContactUs";
import PrivacyPolicy from "./Components/Terms";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Homelayout />,
    errorElement: <Error />,
    children: [
      { path: "", element: <Home /> },
      { path: "About", element: <AboutUs /> },
      { path: "OurServices", element: <OurServices /> },
      { path: "Mortgage", element: <Mortgage /> },
      { path: "Properties", element: <Properties/> },
      { path: "ContactUs", element: <ContactUs/> },
      { path: "PrivacyPolicy", element: <PrivacyPolicy /> },


    ],
  },
]);

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
