import React from "react";
import Silder from "../Silder";
import Why from "../why";
import Explore from "../Explore ";
import ExploreMore from "../ExploreMore";
import Lokking from "../Lokking";
import{Box} from "@material-ui/core"
import Banner from "./Bannr";
const Home = () => {
  
  return (
    <div>
      
      <Silder />
      <Why />
      <Explore />
      
      <ExploreMore />
      
      {/* <Lokking/> */}
    </div>
  );
};

export default Home;
