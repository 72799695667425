import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import { styled } from "@mui/system";
import PrimaryButton from "./Button"; // Ensure you have this component correctly defined and imported
import { apiconfig } from "../Config/ApiConfig";
// Ensure you have your api config set up correctly

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "inter",
    fontSize: "38px",
    fontWeight: "700",
    lineHeight: "58px",
    marginTop: "30px",
    marginBottom: "40px",
  },
  label: {
    fontWeight: "300",
    fontFamily: "inter",
  },
  mapBox: {
    height: "705px",
    marginTop: "50px",
    marginBottom: "83px",
  },
  inputBox: {
    marginBottom: "100px",
  },
  button: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
}));

const FormContainer = styled(Box)({
  padding: "20px",
  borderRadius: "8px",
  margin: "auto",
  backgroundColor: "#fff",
  width: "100%",
  paddingLeft: "49px",
});

const ContactForm = () => {
  const classes = useStyles();

  const fetchData = async (values) => {
    try {
      const res = await axios.post(apiconfig.UserEnqeuri, {
        email: values.email,
        name: values.name,
        mobileNumber: JSON.stringify(values.number),
        location: values.city,
        message: values.additionalInfo,
      });
      if (res.status === 200) {
        toast.success("Form submitted");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      }
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    number: Yup.string().required("Phone number is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    additionalInfo: Yup.string().required("Additional information is required"),
  });

  return (
    <Box
      style={{
        marginBottom: "21px",
        border: "1px solid #000000",
        padding: "36px",
      }}
    >
      <Typography className={classes.heading}>
        Get In Touch With Industry Experts
      </Typography>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <Formik
            initialValues={{
              name: "",
              number: "",
              email: "",
              additionalInfo: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              fetchData(values);
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <ToastContainer position="top-right" />
                <Box className={classes.inputBox}>
                  <Typography className={classes.label}>Name</Typography>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      variant="standard"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </FormControl>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.inputBox}>
                      <Typography className={classes.label}>Phone</Typography>
                      <PhoneInput
                        country={"in"}
                        containerStyle={{ width: "100%" }}
                        inputStyle={{ width: "100%", height: "55px" }}
                        buttonStyle={{ width: "50px" }}
                        value={values.number}
                        onChange={(phone) => {
                          handleChange({
                            target: { name: 'number', value: phone },
                          });
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.number && errors.number && (
                        <Typography color="error">{errors.number}</Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.inputBox}>
                      <Typography className={classes.label}>Email Address</Typography>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          variant="standard"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Box className={classes.inputBox}>
                  <Typography className={classes.label}>Additional Information</Typography>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      variant="standard"
                      type="text"
                      name="additionalInfo"
                      multiline
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.additionalInfo}
                      error={touched.additionalInfo && Boolean(errors.additionalInfo)}
                      helperText={touched.additionalInfo && errors.additionalInfo}
                    />
                  </FormControl>
                </Box>

                <button
                  type="submit"
                  className={classes.button}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.mapBox}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28860.865010401648!2d55.27345336974955!3d25.2006080136957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f434faeabbab5%3A0x8d50ed0c9e2143e0!2sDubai%2C%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1587633976677!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="Dubai Map"
            ></iframe>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm;
